import { auth } from '@config/firebase';
import axios, { type AxiosError, type AxiosRequestConfig } from 'axios';

type ApiClientRequestOptions = Omit<AxiosRequestConfig, 'url' | 'method'>;

type ApiClientParams = {
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS';
  url: string;
  options: Partial<ApiClientRequestOptions> & { rethrow?: boolean };
};

const getHeaders = async (customHeaders: Record<string, unknown> | undefined) => {
  const firebaseIdToken = await auth.currentUser?.getIdToken();

  const defaultHeaders = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${firebaseIdToken}`,
  };

  return {
    ...defaultHeaders,
    ...customHeaders,
  };
};

const client = async <T>(
  method: ApiClientParams['method'],
  url: ApiClientParams['url'],
  options: ApiClientParams['options'] = { timeout: 5000, withCredentials: false, rethrow: false },
) => {
  try {
    const headers = await getHeaders(options.headers);

    return await axios.request<T>({
      ...options,
      method,
      url,
      headers,
    });
  } catch (error: unknown) {
    if ((error as AxiosError).isAxiosError) {
      const axiosError = error as AxiosError;
      console.error(
        `API request error: ${axiosError.response?.status} - ${axiosError.response?.statusText}`,
      );
    } else {
      console.error('API request error:', error);
    }
    if (options.rethrow) {
      throw error;
    }
  }
};

export const apiClient = {
  get: <T>(url: string, requestOptions?: ApiClientParams['options']) =>
    client<T>('GET', url, requestOptions),
  post: <T>(url: string, requestOptions?: ApiClientParams['options']) =>
    client<T>('POST', url, requestOptions),
  patch: <T>(url: string, requestOptions?: ApiClientParams['options']) =>
    client<T>('PATCH', url, requestOptions),
  put: <T>(url: string, requestOptions?: ApiClientParams['options']) =>
    client<T>('PUT', url, requestOptions),
  delete: <T>(url: string, requestOptions?: ApiClientParams['options']) =>
    client<T>('DELETE', url, requestOptions),
  options: <T>(url: string, requestOptions?: ApiClientParams['options']) =>
    client<T>('OPTIONS', url, requestOptions),
};
